main {
  .react-table-wrapper {
    position: relative;

    &.mobile-table-wrapper,
    &.licenseplate-table-wrapper,
    &.cleaningcard-table-wrapper {
      .react-table {
        margin-bottom: 0;
        overflow: unset;
      }
    }
  }

  .react-table {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: none;
    overflow: auto;
    margin-bottom: 1.5rem;

    thead {
      color: var(--primaryBgColor);

      tr {
        display: flex;

        th {
          flex: 1;
          text-align: left;
          font-size: 15px;
          font-weight: 300;
          border-right: none;
          padding: 7px 5px;

          &:first-child {
            padding-left: 0;
          }

          &:last-child {
            padding-right: 0;
          }
        }
      }
    }

    tbody {
      tr {
        display: flex;
        align-items: center;

        &:not(:last-child) {
          border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        }

        td {
          border-right: none;
          padding: 7px 5px;
          flex: 1;

          & .fallback-spinner {
            display: flex;
            align-items: center;
            width: 20px;
            height: 20px;

            .loading {
              width: 100%;
              height: 100%;
              top: auto;
              left: auto;
            }
          }

          &:first-child {
            padding-left: 0;
          }

          &:last-child {
            padding-right: 0;
          }

          a:not(.btn):not(.iconLink) {
            color: var(--linkPrimaryColor);
          }
        }

        a.iconLink {
          color: var(--iconBtnPrimaryTextColor);
          border-bottom: 0;
        }
      }
    }

    .rt-noData {
      position: static;
      text-align: center;
      transform: none;
    }

    .Table__pageButton {
      border-radius: 50%;
      width: 25px;
      height: 25px;
      background: var(--btnPrimaryBgColor);
      border-color: var(--btnPrimaryBorderColor);
      border-style: solid;
      color: var(--btnPrimaryColor);
      display: flex;
      justify-content: center;
      align-items: center;

      &:focus {
        outline: none;
      }

      &.btn {
        padding: 0;
      }
    }
  }
}

.Table {
  &__footer {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    align-content: center;
    align-items: center;

    &::after {
      content: "";
      flex: 1;
    }
  }

  &__size {
    flex: 1;
    display: flex;
    align-content: center;
    align-items: center;
    z-index: 0;

    .label {
      margin-right: 6px;
    }

    .size {
      margin-right: 6px;
      width: 30px;
      height: 30px;
      text-align: center;
      cursor: pointer;
      padding: 4px 1px 1px;

      &.active,
      &:hover {
        padding: 3px 0 0;
        border: solid 1px var(--btnPrimaryBorderColor);
        border-radius: 50%;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
      }
    }
  }

  &__pagination {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    flex: 1;
    z-index: 1;
  }

  &__visiblePagesWrapper {
    margin: 0 12px;
  }

  &__pageButton {
    border-radius: 50%;
    width: 25px;
    height: 25px;
    background: $primary;
    border-color: $primary;
    border-style: solid;
    color: $white;

    &:focus {
      outline: none;
    }

    &:disabled {
      opacity: 0.75;
    }
  }
}

.pagination-bottom {
  display: flex;
  justify-content: center;
}

@media #{$media-tablet} {
  main {
    .ReactTable {
      position: static;
      overflow: scroll;

      & > .rt-table {
        display: table;
        table-layout: fixed;
      }
    }
  }
}

@media #{$media-mobile} {
  main {
    .ReactTable {
      position: static;
      overflow: scroll;

      & > .rt-table {
        display: table;
        table-layout: fixed;
      }

      .Table {
        &__footer {
          flex-direction: column;
        }
      }
    }
  }
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;

  span {
    margin: 0 12px;
  }

  & > button {
    border-radius: 50%;
    width: 25px;
    height: 25px;
    background: var(--btnPrimaryBgColor);
    border-color: var(--btnPrimaryBorderColor);
    border-style: solid;
    color: var(--btnPrimaryColor);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;

    &:focus {
      outline: none;
    }

    &:disabled {
      opacity: 0.75;
    }

    &.btn {
      padding: 0;
    }
  }
}

.-loading {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: hsla(0, 0%, 100%, 0.8);
  transition: all 0.3s ease;
  pointer-events: none;
  opacity: 0;
}

.-loading > div {
  position: absolute;
  display: block;
  text-align: center;
  width: 100%;
  top: 50%;
  left: 0;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.6);
  -webkit-transform: translateY(-52%);
  transform: translateY(-52%);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.-loading.-active {
  opacity: 1;
  z-index: 2;
  pointer-events: all;
}

.-loading.-active > div {
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
}

.pagination-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.controlled-pagination-wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  &::after {
    flex: 1;
    content: "";
  }

  .select-page-size {
    display: flex;
    align-items: center;
    flex: 1;
    margin-left: auto;

    .page-size-label {
      margin-right: 6px;
    }

    .page-size-action {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      margin-right: 6px;
      border: none;
      cursor: pointer;

      &:last-child {
        margin-right: 0;
      }

      &.active {
        border: 2px solid var(--btnPrimaryBorderColor);
        border-radius: 50%;
      }
    }
  }
}

.dropdown {
  .btn {
    display: flex;
    border: 0;
    border-radius: 50%;
    padding: 10px;
    background: #f2f4f7;
    color: #475467;
    box-shadow: none;

    &:hover,
    &:focus,
    &:not(:disabled):not(.disabled):active {
      background: #eaecf0;
      color: #475467;
    }
  }

  .dropdown-menu {
    box-shadow: none;

    button {
      &:active {
        background: #f2f4f7;
        color: #475467;
      }
      &:focus {
        outline: none;
      }
    }
  }
}

.toggle-status {
  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.2rem;
    border: 0;
    border-radius: 50%;
    padding: 10px;
    box-shadow: none;

    &.on,
    &.renewable {
      background: #d1fadf;
      color: #039855;

      &--with-text {
        border-radius: 0.6rem;
        padding: 0.3rem 0.6rem;
      }

      &:hover,
      &:focus,
      &:not(:disabled):not(.disabled):active {
        background: #a6f4c5;
        color: #039855;
      }
    }

    &.off,
    &.cancellable {
      background: #fee4e2;
      color: #d92d20;

      &--with-text {
        border-radius: 0.6rem;
        padding: 0.3rem 0.6rem;
      }

      &:hover,
      &:focus,
      &:not(:disabled):not(.disabled):active {
        background: #fecdca;
        color: #d92d20;
      }
    }

    &.extendable {
      background: #f2f4f7;
      color: #475467;

      &:hover,
      &:focus,
      &:not(:disabled):not(.disabled):active {
        background: #eaecf0;
        color: #344054;
      }
    }
  }
}
