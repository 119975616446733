.title {
  background: $white;
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding: 1.3rem;

  &__header {
    margin-bottom: 0;
    text-transform: capitalize;
    color: var(--breadCrumbsPrimaryColor);
  }
}
