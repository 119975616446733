.webshop {
  .row > div {
    &:first-child {
      order: 1;

      @media #{"only screen and (min-width : 992px)"} {
        order: 0;
      }
    }

    &:nth-child(2) {
      margin-bottom: auto;

      @media #{$media-desktop-xl} {
        display: flex;
      }
    }
  }

  /* PRODUCTS */

  .products {
    margin-bottom: 1rem;

    .radio-card-group {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      flex: 1;
      margin-bottom: 0;

      .radio-card {
        flex-basis: 100%;
        margin-bottom: 0;
        border: 1px solid #d0d5dd;
        border-radius: 4px;

        &.error {
          border: 1px solid #d92d20;
          color: inherit;
        }

        @media #{$media-desktop} {
          flex-grow: 0;
          flex-shrink: 1;
          flex-basis: calc(50% - 10px);
        }

        @media #{$media-desktop-lg} {
          flex-grow: 0;
          flex-shrink: 1;
          flex-basis: calc(50% - 10px);
        }

        @media #{$media-desktop-xl} {
          flex-grow: 0;
          flex-shrink: 1;
          flex-basis: calc(33.33% - 15px);
        }

        & > label {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 100%;
          margin-bottom: 0;
          padding: 16px;
        }

        .form-check-input {
          display: flex;
          position: relative;
          margin-top: 0;
          margin-left: auto;
        }

        .due-date {
          margin: 10px 0 0;
          font-size: 12px;
        }

        .title-group {
          padding: 12px 0 0;

          h3 {
            margin-bottom: 5px;
            font-weight: 600;
            font-size: 18px;
          }

          h4 {
            margin-bottom: 0;
            font-weight: 500;
            font-size: 16px;
          }
        }

        .bulk {
          margin-top: 15px;

          .bulk-price {
            display: inline-flex;
            margin-bottom: 4px;
            padding: 2px 12px;
            background: #203b75;
            color: #eaecf5;
            font-weight: 500;
          }

          .bulk-advantage {
            margin-bottom: 0;
          }
        }

        .progress-group {
          margin-top: 25px;

          .progress {
            height: 8px;
            border-radius: 8px;
            margin-bottom: 8px;
          }

          .progress-help-text {
            margin-bottom: 0;
          }
        }

        .bg-progress-bar {
          background: #203b75;
        }
      }
    }
  }

  /* AGREEMENT CARD */
  .pay {
    .accept-agreement {
      .form-check {
        width: 100%;
        justify-content: flex-start;
      }
    }
  }

  .agreement {
    margin-top: 30px;
    margin-bottom: 10px;

    &--desktop {
      margin-bottom: 25px;
    }

    label {
      font-size: 14px;
    }
  }

  /* PURCHASES */

  .purchases {
    flex: 1;
    order: 0;
    margin-bottom: 1rem;

    @media #{$media-tablet} {
      order: 0;
    }

    .product {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      padding: 16px 0;

      @media #{$media-desktop-lg} {
        flex-direction: row;
      }

      &:first-of-type {
        padding-top: 0;
      }

      &:not(:last-child) {
        border-bottom: 1px solid #eaecf0;
      }

      svg {
        flex: 1 0 20px;
        font-size: 20px;
      }

      .fallback-spinner {
        width: 20px;
        height: 20px;
        margin-left: auto;

        .loading {
          top: 0;
          right: 0;
          left: auto;
          width: 20px;
          height: 20px;
        }
      }

      .pdf-icon {
        margin-right: 16px;
      }

      .product-info {
        display: flex;

        .product-title {
          margin-bottom: 4px;
          font-size: 16px;
          font-weight: 500;
        }

        .purchase-date {
          margin-bottom: 4px;
          font-size: 12px;
          font-weight: 300;
        }

        .due-date {
          margin-bottom: 0;
          font-size: 12px;
          font-weight: 300;
        }
      }

      a {
        margin-left: auto;
        border: 0;
      }

      .download-icon {
        margin-left: auto;
      }
    }
  }

  /* EMPTY CART */

  .empty-cart {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    margin-bottom: 20px;

    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    img {
      margin-bottom: 30px;
    }
  }
}

.download-button {
  a {
    color: white;
    border-bottom: 0;
  }

  .fallback-spinner {
    width: 20px;
    height: 20px;
    margin-left: auto;

    .loading {
      top: 0;
      right: 0;
      left: auto;
      width: 20px;
      height: 20px;

      .effects {
        border-left: 3px solid white;
      }
    }
  }
}
