.MyDetails {
  .nav-tabs {
    margin: 0;

    .nav-link {
      cursor: pointer;
      border: none;

      &.active:hover {
        color: var(--primaryTextColor);
      }
    }
  }

  .tab-content {
    .tab-pane {
      .row {
        margin: 0 -15px;
      }
    }
  }

  .card {
    margin-bottom: 1rem;

    .card-title {
      margin-bottom: 1.4rem;
    }
  }

  .col-md-12 {
    display: flex;
    flex-direction: column;
  }

  .vat-number-requested-wrapper {
    margin: 0.5rem 1.25rem 2.2rem;
  }

  .form-group {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 22px;

    & > .input-wrapper {
      flex: 0.9;
    }

    .vat-wrapper {
      flex: 0.9;

      &-item {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;

        div {
          &[name="vatNumber"],
          &[name="vatNumberRequested"] {
            flex: 0.46;
          }
        }
      }
    }

    .address-wrapper {
      flex: 0.9;

      &-item {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;

        div {
          &[name="street"],
          &[name="city"] {
            flex: 0.65;
          }

          &[name="houseNumber"],
          &[name="postalCode"] {
            flex: 0.32;
          }
        }
      }
    }

    &.address {
      .control-label {
        align-self: flex-start;
      }
    }
  }
}

@media #{$media-mobile} {
  .MyDetails {
    .form-group {
      flex-flow: column wrap;
      align-items: stretch;
      margin-bottom: 1.4rem;

      & > .control-label {
        width: auto;
      }

      .address-wrapper {
        .address-wrapper-item {
          .row {
            & > div {
              padding: 0;

              .input-wrapper {
                margin-bottom: 12px;
              }

              &:last-child {
                .input-wrapper {
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }

      .input-wrapper {
        .row {
          & > div {
            padding: 0;
          }
        }
      }
    }
  }
}
